import 'whatwg-fetch/fetch';
import 'babel-polyfill/dist/polyfill';
import Uppy from 'uppy/dist/uppy';

// document.addEventListener('DOMContentLoaded', function () {
$(document).on("turbolinks:load", function() {
  function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
  
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  function fileUpload(fileInput) {
    var imagePreview = document.getElementById(fileInput.dataset.previewElement);

    fileInput.style.display = 'none'; // uppy will add its own file input

    var uppy = Uppy.Core({
      id: fileInput.id,
      restrictions: {
        allowedFileTypes: fileInput.accept.split(','),
      }
    })
    .use(Uppy.FileInput, {
      target: fileInput.parentNode,
    })
    .use(Uppy.Informer, {
      target: fileInput.parentNode,
    })
    .use(Uppy.ProgressBar, {
      target: imagePreview.parentNode,
    });

    uppy.use(Uppy.XHRUpload, {
      server: {
        host: 'harmonie-muenchen.de',
        protocol: 'https',
      },
      endpoint: '/upload', // Shrine's upload endpoint
      fieldName: 'file',
      formData: true,
      // debug: true
    });

    uppy.on('upload-success', function (file, data, uploadURL) {
      // show image preview
      imagePreview.src = URL.createObjectURL(file.data);
      var uploadedFileData = JSON.stringify(data);
      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      var hiddenInput = document.getElementById(fileInput.dataset.uploadResultElement);
      hiddenInput.value = uploadedFileData;
    });

    return uppy;
  }

  document.querySelectorAll('input[type=file]').forEach(function (fileInput) {
    if (fileInput.multiple) {
      fileInput.addEventListener('change', function (event) {
        Array.from(fileInput.files).forEach(function (file) {
          // create a new copy of the resource for the selected file
          var template = document.getElementById(fileInput.dataset.template);
          var uploadList = document.getElementById(fileInput.dataset.uploadList);
          // uploadList.insertAdjacentHTML('beforeend', template.innerHTML.replace(/{{index}}/g, ''));
          uploadList.insertAdjacentHTML('beforeend', template.innerHTML.replace(/{{index}}/g, guid()));

          // trigger file upload on the new resource
          var singleFileInput = uploadList.lastElementChild.querySelector('input[type=file]');
          var uppy = fileUpload(singleFileInput);
          uppy.addFile({name: file.name, type: file.type, data: file});
        });

        // remove selected files
        fileInput.value = '';
      });
    } else {
      fileUpload(fileInput);
    }
  });
});
