// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

// -------------------
// Import css files
// -------------------

// Big Calendar
import 'react-big-calendar/lib/css/react-big-calendar'

// Application CSS
import '../stylesheets/application'

// Lightbox 2
import 'lightbox2/src/css/lightbox.css'

// react-vis für die Statistik
// import 'react-vis/dist/style'

// -------------------
// Import JS libraries
// -------------------

import $ from 'jquery'
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
Rails.start()
Turbolinks.start()

// Bulma JS Stuff
import bulmaCollapsible from '@creativebulma/bulma-collapsible/dist/js/bulma-collapsible.min.js'

// Support component names relative to this directory:

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

// React On Rails

import ReactOnRails from 'react-on-rails'
import HuettenDayRange from '../components/HuettenDayRange'
import EditReservierung from '../components/EditReservierung'
import HuettenKalender from '../components/HuettenKalender'
ReactOnRails.register({ EditReservierung })
ReactOnRails.register({ HuettenKalender })
ReactOnRails.register({ HuettenDayRange })

import HuettenStatistik from '../components/HuettenStatistik'
ReactOnRails.register({ HuettenStatistik })

// Setup axios default header with token

import setupCSRFToken from '../javascripts/setupCSRFToken'
setupCSRFToken()

// Navigationbar Functions
import '../javascripts/navbar'

// photo and image upload
import '../javascripts/photo_upload'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/effects/effect-highlight'

// Masonry layout and lightbox
import '../javascripts/photo_layout'
import lightbox from 'lightbox2/dist/js/lightbox'

// import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis'

// Tabs navigation for the two huts

$(document).on("turbolinks:load", function() {
  document.getElementsByClassName("hero")[0].style.top = 0;
  // $('section.hero').css('top',0)
  $(window).scroll(function () {
    var heroRect = document.getElementsByClassName("hero")[0].getBoundingClientRect()
    var stickyOffset = -heroRect.height+152
    if (stickyOffset > 0) stickyOffset = 0
    $('section.hero').css('top',stickyOffset)

    var heroTitle = document.getElementById('vereins-titel')
    if (heroTitle) {
      var contentScrollTop = heroTitle.getBoundingClientRect().y
      if (contentScrollTop < 162) {
        document.getElementById("vereins-titel").style.opacity = 0;
      }
      if (contentScrollTop >= 162) {
        document.getElementById("vereins-titel").style.opacity = 1;
      }
      if (heroRect.y-stickyOffset == 0) {
        document.getElementsByClassName("hero-body")[0].classList.add("transparent-after");
      } else {
        document.getElementsByClassName("hero-body")[0].classList.remove("transparent-after");
      }
    }
  })

  lightbox.option({
    'resizeDuration': 200,
    'wrapAround': false
  })
  lightbox.init()


  // Return an array of bulmaCollapsible instances (empty if no DOM node found)
  const bulmaCollapsibleInstances = bulmaCollapsible.attach('.is-collapsible');
  // Loop into instances
  bulmaCollapsibleInstances.forEach(bulmaCollapsibleInstance => {
    // Check if current state is collapsed or not
    console.log(bulmaCollapsibleInstance.collapsed());
  });

  // Find DOM node from ID
  const collapsibleElement = document.getElementById('bulma-collapsible')
  if (collapsibleElement) {
    // Call method directly on Collapsible instance registered on the node
    collapsibleElement.bulmaCollapsible().on('after:expand', (e) => {
      $('a.toggle-collapsible').html('Schliessen')
    })
    // Call method directly on Collapsible instance registered on the node
    collapsibleElement.bulmaCollapsible().on('after:collapse', (e) => {
      $('a.toggle-collapsible').html('Öffnen')
    })
  }

  // Find DOM node from ID
  const photoEditElement = document.getElementById('photo-collapsible')
  if (photoEditElement) {
    // Call method directly on Collapsible instance registered on the node
    photoEditElement.bulmaCollapsible().on('after:expand', (e) => {
      $('a.toggle-photo-edit').html('Schliessen')
    })
    // Call method directly on Collapsible instance registered on the node
    photoEditElement.bulmaCollapsible().on('after:collapse', (e) => {
      $('a.toggle-photo-edit').html('Öffnen')
    })
  }

  // Find DOM node from ID
  const markDownInfo = document.getElementById('markdown-collapsible')
  if (markDownInfo) {
    // Call method directly on Collapsible instance registered on the node
    markDownInfo.bulmaCollapsible().on('after:expand', (e) => {
      $('a.toggle-markdown').html('Schliessen')
    })
    // Call method directly on Collapsible instance registered on the node
    markDownInfo.bulmaCollapsible().on('after:collapse', (e) => {
      $('a.toggle-markdown').html('Öffnen')
    })
  }

  // sort gallery pictures
  $(function() {
    if ($('#sortable').length > 0) {
      return $('#sortable').sortable({
        axis: 'y',
        items: '.item',
        cursor: 'move',
        sort: function(e, ui) {
          return ui.item.addClass('active-sort-item')
        },
        stop: function(e, ui) {
          ui.item.removeClass('active-sort-item')
          return ui.item.children('td').effect('highlight', {}, 1000)
        },
        update: function(e, ui) {
          var item_id, position
          item_id = ui.item.data('photo-id')
          position = ui.item.index()
          const csrfToken = document.querySelector("meta[name=csrf-token]").content
          return $.ajax({
            type: 'POST',
            url: '/articles/' + ui.item.data('article-id') + '/photos/update_photo_order',
            dataType: 'json',
            beforeSend: function(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            data: {
              photo: {
                photo_id: item_id,
                photo_order_position: position
              }
            }
          })
        }
      })
    }
  })
})

// Initialize all input of type date
// const calendar = bulmaCalendar.attach('[type="date"]');

// Loop on each calendar initialized
// calendar.on('select', date => {
//   console.log(date);
// });

// To access to bulmaCalendar instance of an element
// var element = document.querySelector('#my-element');
// if (element) {
//   // bulmaCalendar instance is available as element.bulmaCalendar
//   element.bulmaCalendar.on('select', function(datepicker) {
//     console.log(datepicker.data.value());
//   });
// }
