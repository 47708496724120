import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
require('moment/locale/de');

export default class HuettenDayRange extends React.Component {

  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.updateRange = this.updateRange.bind(this);
  }

  updateRange(range) {
    this.props.onUpdateRange(range);
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, {from: this.props.from, to: this.props.to});
    this.updateRange(range);
  }

  handleResetClick() {
    const range = {from: null, to: null};
    this.updateRange(range);
  }

  render() {
    const { from, to } = this.props.timerange;
    const modifiers = { start: from, end: to };
    return (
      <div className="HuettenDayRange">
        <p>
          {!from && !to && <span className="has-text-danger">Bitte den Anreisetag selektieren.</span>}
          {from && !to && <span className="has-text-danger">Bitte den Abreisetag selektieren.</span>}
          {from && to && <span className="has-text-success">Reserviert von {moment(from).format('DD. MMM YYYY')} bis {moment(to).format('DD. MMM YYYY')}</span>}
          {' '}
          {from &&
            to && (
              <button className="link" onClick={this.handleResetClick}>
                Reset
              </button>
            )}
        </p>
        <DayPicker
          className="Selectable"
          month={from ? new Date(from) : new Date()}
          numberOfMonths={this.props.numberOfMonths}
          localeUtils={MomentLocaleUtils}
          locale='de'
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
        <Helmet>
          <style>{`
            .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #19758c;
            }
            .Selectable .DayPicker-Day {
              border-radius: 0 !important;
            }
            .Selectable .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
              background-color: #19758c !important;
            }
            .Selectable .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
              background-color: #19758c !important;
            }
          `}</style>
        </Helmet>
      </div>
    );
  }
}

// https://codepen.io/Yuschick/post/react-passing-state-to-props-children-in-jsx