import React, { Component } from 'react';
import { XAxis, YAxis, HorizontalGridLines, VerticalBarSeries, LabelSeries } from 'react-vis';
import {
  FlexibleXYPlot,
} from 'react-vis';

// const FlexibleXYPlot = makeVisFlexible(XYPlot);
// const FlexibleWidthXYPlot = makeWidthFlexible(XYPlot);
// const FlexibleHeightXYPlot = makeHeightFlexible(XYPlot);

export default class HuettenStatistik extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      booking_list:this.props.statistik_huette
    }
  }

  render() {
    const Chart = () => {
      var booking = this.state.booking_list
      var dataArr = []
      for(var i in booking) {
        dataArr.push({x: i.substr(8,9), y: booking[i], label: booking[i]});
      }
    
      return (
        <FlexibleXYPlot
          xType='ordinal'
          height={400}
          width={1184}
          yDomain={[0, 40]}>
          {/* <VerticalGridLines

          />
          <HorizontalGridLines

          /> */}
          <XAxis
            attr='x'
            attrAxis='y'
            orientation='bottom'
            position='end'
            title='Tag' />
          <YAxis
            attr='y'
            attrAxis='x'
            orientation='left'
            position='end'
            title='Anzahl' />
          <VerticalBarSeries
            data={dataArr}
            color='#18748b'
            opacity={0.5}
          />
          <LabelSeries
            animation
            data={dataArr}
          />
        </FlexibleXYPlot>
      )
    }

    return (
      <div className='huetten-statistik'>
        <Chart />
      </div>
    )
  }
}
