import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import axios from 'axios';
import moment from 'moment';
import HuettenDayRange from '../components/HuettenDayRange';

require('moment/locale/de');

export default class InfoReservierung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.openInfoPanel = this.openInfoPanel.bind(this);
    this.openCreatePanel = this.openCreatePanel.bind(this);
    this.afterOpenPanel = this.afterOpenPanel.bind(this);

    this.closePanel = this.closePanel.bind(this);
  }

  //-----------
  // Open Panel
  //-----------

  openInfoPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    this.setState({
      open: true
    });
  }

  openCreatePanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    this.setState({
      open: true
    });
  }

  afterOpenPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    // this.subtitle.style.color = '#f00';
  }

  //------------
  // Close Panel
  //------------

  closePanel() {
    this.props.onClosePanel();
    this.setState({
      open: false
    });
  }

  render() {
    return(
      <div>
        <ReactModal
          isOpen={this.state.open}
          onAfterOpen={this.afterOpenPanel}
          onRequestClose={this.closePanel}
          className='modal is-active'
          overlayClassName='Overlay'
          contentLabel='Example Modal'
          ariaHideApp={false} >
          <div className='modal-background'></div>
          <div className='modal-content'>
            <header className='modal-card-head'>
              <p className='modal-card-title has-text-info' ref={subtitle => this.subtitle = subtitle}>
                {this.props.current_huette}
              </p>
              <button onClick={this.closePanel} className='delete' aria-label='schliessen'></button>
            </header>
            <section className='modal-card-body'>
              <h4 className='title is-5'>{this.props.event.anlass}</h4>
              { this.props.event.anzahl > 0
                ? <h4 className='subtitle is-6'>Anzahl der Personen: <b>{this.props.event.anzahl}</b></h4>
                : ''
              }
              <h5 className='subtitle is-6'>
                Eingetragen von <b>{this.props.event.user}</b> am <b>{moment(this.props.event.created_at).format('DD.MMM YYYY')}</b>
              </h5>
            </section>
            <footer className='modal-card-foot'>
              <button value='Close' className='button' aria-label='schliessen' onClick={this.closePanel}>Schliessen</button>
            </footer>
          </div>
        </ReactModal>
      </div>
    )
  }
}

// from={this.state.selected_range.von}
// to={this.state.selected_range.bis
// reset component: https://medium.com/@justintulk/best-practices-for-resetting-an-es6-react-components-state-81c0c86df98d
// https://www.youtube.com/watch?time_continue=2&v=XLJN4JfniH4
// Look at new context feature. Maybe a better solution for data: https://reactjs.org/docs/context.html