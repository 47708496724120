document.addEventListener('turbolinks:load', function () {

  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target
        var $target = document.getElementById(target)

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }

})

document.addEventListener('turbolinks:load', function () {

  var el = document.querySelector('#modal-login')
  if(el){
    document.querySelector('#modal-login a').addEventListener('click', function(event) {
      event.preventDefault()
      var modal = document.querySelector('.modal')  // assuming you have only 1
      var html = document.querySelector('html')
      modal.classList.add('is-active')
      html.classList.add('is-clipped')

      modal.querySelector('.modal-background').addEventListener('click', function(e) {
        e.preventDefault()
        modal.classList.remove('is-active')
        html.classList.remove('is-clipped')
      })

      modal.querySelector('button.modal-close').addEventListener('click', function(e) {
        e.preventDefault()
        modal.classList.remove('is-active')
        html.classList.remove('is-clipped')
      })
    })
  }
})

document.addEventListener('turbolinks:load', function () {
  $('.navbar-item.has-dropdown .navbar-link').click(function (e) {
    if ($('.navbar-burger').is(':visible')) {
      $(this).parent().toggleClass('is-active')
    }
  })
  $('.navbar-link').click(function (e) {
    if ($('.navbar-burger').is(':visible')) {
      e.preventDefault()
    }
  })
  $(window).resize(function (e) {
    if (!$('.navbar-burger').is(':visible') && $('.navbar-item.has-dropdown.is-active').length) {
      $('.navbar-item.has-dropdown.is-active').removeClass('is-active')
    }
  })
})