import Masonry from 'masonry-layout/dist/masonry.pkgd'
import ScrollReveal from 'scrollreveal/dist/scrollreveal'
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd'

$(document).on("turbolinks:load", function() {
  var el = document.getElementById('masonry')
  if(el){
    imagesLoaded( '#masonry', function() {
      var container = document.querySelector('#masonry')
      var masonry = new Masonry(container, {
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        gutter: '.gutter',
        horizontalOrder: true,
        percentPosition: true
      })
      masonry.layout()
      ScrollReveal().reveal('.grid-item', { duration: 800 })
    })
  }		
})
