import React, {Children} from 'react';
import BigCalendar from 'react-big-calendar';
import axios from 'axios';

// https://momentjs.com/docs/;
import moment from 'moment';
import EditReservierung from '../components/EditReservierung';
import InfoReservierung from '../components/InfoReservierung';

require('moment/locale/de');
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer


const messages = {
  allDay: 'Tag',
  previous: 'Vorheriger',
  next: 'Nächster',
  today: 'Aktueller Monat',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Zeit',
  event: 'Ereignis'
};

const dateFormat = /^\d{4}:\d{2}:\d{2}T\d{2}-\d{2}-\d{2}/;

Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '-' : '-0') + mm,
          (dd>9 ? '-' : '-0') + dd
         ].join('');
};

function reviver(key, value) {
    if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
}

const HutEvent = ({ event }) => (
  <div>
    <span className={'event-anlass'}>
      {event.anlass}
    </span>
    <span className={(event.anzahl > 0 ? 'is-pulled-right' : 'is-hidden')}>
      {event.anzahl} <i className="fa fa-user"></i>
    </span>
  </div>
)

function eventStyleGetter(event, start, end, isSelected) {
  var backgroundColor;
  if (event.anzahl > 0) {
    backgroundColor =  '#19758c';
  } else {
    backgroundColor =  '#b51f1f';
  }
  return {
      style: {
        backgroundColor: backgroundColor
    }
  }
}

export default class HuettenKalender extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      culture: 'de',
      selectedDate: this.props.date,
      events: this.props.reservierungen,
      booking_list: this.props.booking_list,
      current_user: this.props.current_user,
      current_user_id: this.props.current_user_id,
      current_user_role: this.props.current_user_role,
      current_huette: this.props.current_huette,
      current_huette_id: this.props.current_huette_id,
      selected_event: {id: null,huette: null,huette_id: null,user: null,user_id: null,created_at: null,anlass: null,von: null,bis: null,anzahl: null, user_role: null},
      editable: false
    }

    this.openEditPanel = this.openEditPanel.bind(this)
    this.openInfoPanel = this.openInfoPanel.bind(this)
    this.openCreatePanel = this.openCreatePanel.bind(this)
    this.changeMonth = this.changeMonth.bind(this)
  }

  render() {
    const isLoggedIn = (this.props.current_user_role >= 5);

    // Custom Month Date Header
    const MonthDateHeader = ({ label, date }) => {
      const count = this.state.booking_list[date.yyyymmdd()]
      const maxCount = this.state.current_huette_id == 2 ? 30 : 17
      if (Number(count) > 0) {
        if (count - maxCount > 0) {
          return (
            <div>
              {label}
              <span className={'count-of-bookings has-text-danger is-pulled-right is-hidden-touch'}>
                Zuviel: +{count-maxCount}
              </span>
              <span className={'count-of-bookings has-text-danger is-pulled-right is-hidden-desktop'}>
                +{count-maxCount}
              </span>
            </div>
          )
          } else if (count - maxCount == 0) {
          return (
            <div>
              {label}
              <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-touch'}>
                Voll: {maxCount}
              </span>
              <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-desktop'}>
                {maxCount}
              </span>
            </div>
          )
        } else {
          return (
            <div>
              {label}
              <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-touch'}>
                Frei: {maxCount-count}
              </span>
              <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-desktop'}>
                {maxCount-count}
              </span>
            </div>
          )
        }
      // } else if (Number(count) == 0) {
      //   return (
      //     <div>
      //       {label}<br></br>
      //       <b className={'count-of-bookings has-text-danger'}>
      //         Geschlossen!
      //       </b>
      //     </div>
      //   )
      } else {
        return (
          <div>
            {label}
            {/* <br></br>
            <b className={'count-of-bookings has-text-info'}>
              Frei {count}
            </b> */}
          </div>
        )
      }
    }

    return(
      <div>
        <BigCalendar
          localizer={localizer}
          date={new Date(this.state.selectedDate)}
          events={this.state.events}
          startAccessor='von'
          endAccessor='bis'
          views={['month']}
          step={60}
          selectable
          popup={true}
          // formats={formats}
          onNavigate={date => this.changeMonth(date)}
          onSelectEvent={event => (isLoggedIn && (this.props.current_user_id == event.user_id || this.props.current_user_role > event.user_role)) ? this.openEditPanel(event) : this.openInfoPanel(event)}
          onSelectSlot={slotInfo => isLoggedIn ? this.openCreatePanel(slotInfo) : null}
          eventPropGetter={eventStyleGetter}
          components={{
            month: {
              event: HutEvent,
              dateHeader: MonthDateHeader
            }
          }}
          messages={messages}
        />
        <EditReservierung
        ref='EditEventPanel'
        event={this.state.selected_event}
        updateEvent = {this.updateEvent.bind(this)}
        deleteEvent = {this.deleteEvent.bind(this)}
        onClosePanel = {this.onClosePanel.bind(this)}
        current_user={this.props.current_user}
        current_user_id={this.props.current_user_id}
        current_huette={this.props.current_huette}
        current_huette_id={this.props.current_huette_id}
        />
        <InfoReservierung
        ref='InfoEventPanel'
        event={this.state.selected_event}
        onClosePanel = {this.onClosePanel.bind(this)}
        current_user={this.props.current_user}
        current_user_id={this.props.current_user_id}
        current_huette={this.props.current_huette}
        current_huette_id={this.props.current_huette_id}
        />
      </div>
    )
  }
  
  openEditPanel(event) {
    this.setState({selected_event: event});
    this.refs.EditEventPanel.openEditPanel();
  }

  openInfoPanel(event) {
    this.setState({selected_event: event});
    this.refs.InfoEventPanel.openInfoPanel();
  }
 
  openCreatePanel(slotInfo) {
    this.setState({
      selected_event: {
        id: null,
        huette: this.props.current_huette,
        huette_id: this.props.current_huette_id,
        user: this.props.current_user,
        user_id: this.props.current_user_id,
        created_at: slotInfo[0],
        anlass: '',
        von: slotInfo.start,
        bis: slotInfo.end,
        anzahl: ''
      }
    });
    this.refs.EditEventPanel.openCreatePanel();
  }
 
  onClosePanel() {
    this.setState({selected: false});
  }

  // change month 
 
  changeMonth(date) {
    const huette_id = this.props.current_huette_id;
    axios.get('/reservierungen.json', {
      params: {
        date: date
      }
    })
    .then(response => {
      var answer = [];
      var booking = [];
      if ( huette_id == 1) {
        answer = JSON.parse(JSON.stringify(response.data.huette_1), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_1), reviver);
      } else {
        answer = JSON.parse(JSON.stringify(response.data.huette_2), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_2), reviver);
      }
      this.setState({events: answer, booking_list: booking});
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    })
  }
 
  // update event 
 
  updateEvent = () => {
    const huette_id = this.props.current_huette_id;
    const event = this.state.selected_event;
    // const self = this;
    axios.post('/reservierungen.json', {
      reservierung: {
        id: event.id ? event.id : null,
        huette_id: event.huette_id ? event.huette_id : this.props.current_huette_id,
        user_id: this.props.current_user_id,
        // user_id: event.user_id ? event.user_id : this.props.current_user_id,
        anlass: event.anlass,
        von: event.von,
        bis: event.bis,
        anzahl: event.anzahl
      }
    }).then(response => {
      var answer = [];
      var booking = [];
      if ( huette_id == 1) {
        answer = JSON.parse(JSON.stringify(response.data.huette_1), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_1), reviver);
      } else {
        answer = JSON.parse(JSON.stringify(response.data.huette_2), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_2), reviver);
      }
      this.setState({events: answer, event: null, selected: null, booking_list: booking});
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }
 
  // delete event 
 
  deleteEvent = () => {
    const huette_id = this.props.current_huette_id;
    // const self = this;
    axios.delete('/reservierungen/'+this.state.selected_event.id)
    .then(response => {
      var answer = [];
      var booking = [];
      if ( huette_id == 1) {
        answer = JSON.parse(JSON.stringify(response.data.huette_1), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_1), reviver);
      } else {
        answer = JSON.parse(JSON.stringify(response.data.huette_2), reviver);
        booking = JSON.parse(JSON.stringify(response.data.booking_list_2), reviver);
      }
      this.setState({events: answer, event: null, selected: null, booking_list: booking});
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }
}


// Forms in React https://lorenstewart.me/2016/10/31/react-js-forms-controlled-components/
// https://github.com/intljusticemission/react-big-calendar/issues/419

// https://codesandbox.io/s/jp1931172w

// let formats = {
//   // Format for the day of the month heading in the Month view
//   dateFormat: 'DD',
//   // Format for the month week day headings
//   weekdayFormat: 'dd',
//
//   agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
//     local.format(start, { date: 'short' }, culture) + ' — ' +
//     local.format(end, { date: 'short' }, culture),
// }

// let formats={
//       timeGutterFormat: (date, culture, localizer) =>
//         localizer.format(date, 'HH:mm', culture),
//       eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
//         let s = localizer.format(start, 'HH:mm', culture);
//         let e = localizer.format(end, 'HH:mm', culture);
//         return `${s} - ${e}`;
//       },
//       agendaTimeRangeFormat: ({ start, end }, culture, localizer) => {
//         let s = localizer.format(start, 'HH:mm', culture);
//         let e = localizer.format(end, 'HH:mm', culture);
//         return `${s} - ${e}`;
//       },
//       dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
//         let s = localizer.format(start, 'MMM DD', culture);
//         let e = localizer.format(end, 'MMM DD', culture);
//         return `${s} - ${e}`;
//       }
//     }

// Formats

// let formats = {
//   eventTimeRangeFormat: ({
//       start,
//       end
//     }, culture, local) =>
//     local.format(start, 'DD.MM.YYYY', culture) + '-' +
//     local.format(end, 'DD.MM.YYYY', culture),
//   dayFormat: 'DD.MM.YYYY',
//   agendaTimeRangeFormat: ({
//       start,
//       end
//     }, culture, local) =>
//     local.format(start, 'HH:mm', culture) + '-' +
//     local.format(end, 'HH:mm', culture),
//   agendaDateFormat: 'MM-DD' + ' ' + '星期' + 'dd',
//
// }