import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import axios from 'axios';
import moment from 'moment';
import HuettenDayRange from '../components/HuettenDayRange';

require('moment/locale/de');

export default class EditReservierung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected_range: {
        from: null,
        to: null
      }
    };

    this.openEditPanel = this.openEditPanel.bind(this);
    this.openCreatePanel = this.openCreatePanel.bind(this);
    this.afterOpenPanel = this.afterOpenPanel.bind(this);

    this.closePanel = this.closePanel.bind(this);

    this.closeModalAndUpdateEvent = this.closeModalAndUpdateEvent.bind(this);
    this.closeModalAndDeleteEvent = this.closeModalAndDeleteEvent.bind(this);

    this.onUpdateRange = this.onUpdateRange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  //-----------
  // Open Panel
  //-----------

  openEditPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    this.setState({
      open: true
    });
  }

  openCreatePanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    this.setState({
      open: true
    });
  }

  afterOpenPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    });
    // this.subtitle.style.color = '#f00';
  }

  //------------
  // Close Panel
  //------------

  closePanel() {
    this.setState({
      selected_range: {
        from: null,
        to: null
      }
    });
    this.props.onClosePanel();
    this.setState({
      open: false
    });
  }

  closeModalAndDeleteEvent() {
    if (confirm('Diese Reservierung wirklich löschen?')) {
      this.props.deleteEvent();
      this.closePanel();
    } else {
      return;
    }
  }

  closeModalAndUpdateEvent() {
    if (this.state.selected_range.from && this.state.selected_range.to && this.state.event.anlass && this.state.event.anzahl>=0) {
      this.props.updateEvent();
      this.closePanel();
    } else {
      return;
    }
  }

  onUpdateRange(range) {
    this.setState({
      selected_range: range
    });
    this.props.event.von = range.from;
    this.props.event.bis = range.to;
    this.setState({
      event: this.props.event
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.props.event[name] = value;
    this.setState({
      event: this.props.event
    });
  }

  render() {
    return(
      <div>
        <ReactModal
          isOpen={this.state.open}
          onAfterOpen={this.afterOpenPanel}
          onRequestClose={this.closePanel}
          className='modal is-active'
          overlayClassName='Overlay'
          contentLabel='Example Modal'
          ariaHideApp={false} >
          <div className='modal-background'></div>
          <div className='modal-content'>
            <header className='modal-card-head'>
              <p className='modal-card-title has-text-info' ref={subtitle => this.subtitle = subtitle}>
                {this.props.current_huette}
              </p>
              <button onClick={this.closePanel} className='delete' aria-label='schliessen'></button>
            </header>
            <section className='modal-card-body'>
              <h4 className='subtitle is-5'>
                Eingetragen von <b>{this.props.event.user}</b> am <b>{moment(this.props.event.created_at).format('DD.MMM YYYY')}</b>
              </h4>
              <form className='edit_reservierung' id='edit_reservierung_1496' method='post'>
                <input name='utf8' type='hidden' value='✓'/>
                <input type='hidden' name='_method' value='patch'/>
                <input type='hidden' name='authenticity_token' value={this.props.event.authenticity_token}/>
                <div className='field'>
                  <label className='label' htmlFor='anlass'>Anlass:</label>
                  <div className='control'>
                    <input className='input' type='text' id='reservierung_anlass' value={this.props.event.anlass} name='anlass' onChange={this.handleInputChange.bind(this)}/>                
                  </div>
                </div>
                <div className='field'>
                  <label className='label' htmlFor='anzahl'>Anzahl der Personen:</label>
                  <div className='control'>
                    <input className='input' type='number' id='reservierung_anzahl' value={this.props.event.anzahl} name='anzahl'  onChange={this.handleInputChange}/>
                  </div>
                </div>
                <HuettenDayRange
                  from = {this.state.selected_range.from}
                  to = {this.state.selected_range.to}
                  timerange = {this.state.selected_range}
                  onUpdateRange = {this.onUpdateRange}
                />
                  
                {/*<div className='field'>
                  <label className='label' htmlFor='von'>Von:</label>
                  <div className='control'>
                    <input className='input' type='date' id='reservierung_von' value={this.state.selected_event.von}  name='von' onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className='field'>
                  <label className='label' htmlFor='bis'>Bis:</label>
                  <div className='control'>
                    <input className='input' type='date' id='reservierung_bis' value={this.state.selected_event.bis} name='bis' onChange={this.handleInputChange}/>
                  </div>
                </div>*/}

              </form>
            </section>
            <footer className='modal-card-foot'>
              <button value='Save' className='button is-success' onClick={this.closeModalAndUpdateEvent}>Sichern</button>
              <button value='Delete' className='button is-danger' onClick={this.closeModalAndDeleteEvent}>Löschen</button>
              <button value='Close' className='button' aria-label='schliessen' onClick={this.closePanel}>Schliessen</button>
            </footer>
          </div>
        </ReactModal>
      </div>
    )
  }
}

// from={this.state.selected_range.von}
// to={this.state.selected_range.bis
// reset component: https://medium.com/@justintulk/best-practices-for-resetting-an-es6-react-components-state-81c0c86df98d
// https://www.youtube.com/watch?time_continue=2&v=XLJN4JfniH4
// Look at new context feature. Maybe a better solution for data: https://reactjs.org/docs/context.html